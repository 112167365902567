import classNames from 'classnames';

export default ({ name, classes, errors = [], ...props }) => {
    const className = classNames(
        [
            `input input-bordered ${classes}`
        ],
        {
            'input-error': errors.length
        }
    );

    return (
        <input
            id={name}
            name={name}
            {...props}
            className={className}
        />
    );
};